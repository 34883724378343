import http from "./axios";

// 查询告警组分页列表
export const getDisk = (data) => {
  return http.get("/server/disk/page", data);
};

// 查询服务器集合
export const serverList = (data) => {
  return http.get("/delivery/server/list?enabled=true", data);
};

// 修改告警阈值
export const editAlarmThreshold = (data) => {
  return http.json_post("/server/disk/edit", data);
};
