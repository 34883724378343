import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import yapi from "../views/yapi.vue";
import slb from "../views/slb.vue";
import ruleList from "../components/ruleList.vue";
import zookeeperUI from "../views/zookeeperUI.vue";
import projectList from "../components/projectList.vue";
import projectManagement from "../components/projectManagement.vue";
import timeControlModelList from "../components/timeControlModelList.vue";
import postApplication from "../components/postApplication.vue";
import workbench from "../components/workbench.vue";
import nginxGateway from "../components/nginxGateway.vue";
import mainAccountPage from "../components/mainAccountPage";
import policyPage from "../components/policyPage";
import apiGateway from "../components/apiGateway.vue";
import mvnManagement from "../components/mvnManagement.vue";
import moduleList from "../components/moduleList.vue";
import manualUpload from "../components/manualUpload.vue";
import zookeeperUIList from "../components/zookeeperUIList.vue";
import projectPom from "../components/projectPom.vue";
import allApiList from "../components/allApiList.vue";
import apiDetail from "../components/apiDetail.vue";
import subprojectList from "../components/subprojectList.vue";
import publishLog from "../components/publishLog.vue";
import serverAccountListTab from "../components/serverAccountListTab.vue";
import serverConfigTemplate from "../components/serverConfigTemplate.vue";
import serverPortInfoList from "../components/serverPortInfoList.vue";
import shellLog from "../components/shellLog.vue";
import serverGroup from "../components/serverGroup.vue";
import serverListTab from "../components/serverListTab.vue";
import serverList from "../components/serverList.vue";
import request from "../components/request.vue";
import statistics from "../components/statistics.vue";
import postingDetails from "../components/postingDetails.vue";
import otherDeploy from "../components/otherDeploy.vue";
import compiler from "../components/compiler";
import environment from "../components/environment";
import errorLog from "../components/errorLog.vue";
import errorLogList from "../components/errorLogList.vue";
import errorLogStatistics from "../components/errorLogStatistics.vue";
import batchExecute from "../components/batchExecute.vue";
import shell from "../components/shell.vue";
import shellTiming from "../components/shellTiming";
import shellExecute from "../components/shellExecute";
import domainNameDNS from "../components/domainNamedns.vue";
import domainAdmin from "../components/domainAdmin.vue";
import aliyunAccountManagement from "../components/aliyunAccountManagement.vue";
import certificateManagement from "../components/certificateManagement";
import certificateDomain from "../components/certificateDomain";
import ramAccountPage from "../components/ramAccountPage.vue";
import vpnManager from "../components/vpnManager.vue";
import certificate from "../components/certificate.vue";
import diskManage from "../components/diskManage.vue";
import serverDiskList from "../components/serverDiskList.vue";
import diskList from "../components/diskList.vue";
import securityGroupManagement from "../components/securityGroupManagement.vue";
import terminal from "../views/terminal.vue";
import securityGroupItem from "../components/securityGroupItem.vue";
import publickeyManagement from "../components/publickeyManagement.vue";
import ansible from "../components/ansible.vue";
import ansiblePlaybook from "../components/ansiblePlaybook.vue";
import ansibleTask from "../components/ansibleTask.vue";
import publickeyList from "../components/publickeyList.vue";
import trustList from "../components/trustList.vue";
import interfaceView from "../pages/interface/interfaceView.vue";
import warning from "../components/warning.vue";
import warningGroupPage from "../components/warningGroupPage";
import warningConfigPage from "../components/warningConfigPage";
import warningMemberPage from "../components/warningMemberPage";
import warningRecordPage from "../components/warningRecordPage";
import topic from "../components/topic.vue";
import mqTopic from "../components/mqTopic.vue";
import mqConsumer from "../components/mqConsumer.vue";
import mqCluster from "../components/mqCluster.vue";
import mqProducer from "../components/mqProducer.vue";
import mqTopicDetail from "../components/mqTopicDetail.vue";
import mqMessage from "../components/mqMessage.vue";
import monitor from "../components/monitor.vue";
import nginxInstanceList from "../components/nginxInstanceList.vue";
import nginxInstance from "../components/nginxInstance.vue";
import globalConfiguration from "../components/globalConfiguration.vue";
import nginxServerGroup from "../components/nginxServerGroup.vue";
import listenServerGroupList from "../components/listenServerGroupList.vue";
import listenServerGroupItemList from "../components/listenServerGroupItemList.vue";
import serverGroupList from "../components/serverGroupList.vue";
import nginxServer from "../components/nginxServer.vue";
import generatorCode from "../components/generatorCode.vue";
import templateCode from "../components/templateCode.vue";
import templated from "../components/templated.vue";
import templateParameter from "../components/templateParameter.vue";
import templateProject from "../components/templateProject.vue";
import interfaceManagement from "../components/interfaceManagement.vue";
import innerApi from "../components/innerApi.vue";
import outerApi from "../components/outerApi.vue";
import workbenchType from "../components/workbenchType.vue";
import flameGraphFile from "../components/flameGraphFile.vue";
import sshShell from "../components/sshShell.vue";
import pipeline from "../components/pipeline.vue";
import nodeList from "../components/nodeList.vue";
import event from "../components/event.vue";
import eventPage from "../components/eventPage.vue";
import podList from "../components/podList.vue";
import deploymentTab from "../components/deploymentTab.vue";
import deploymentList from "../components/deploymentList.vue";
import statefulSetTab from "../components/statefulSetTab.vue";
import statefulSetList from "../components/statefulSetList.vue";
import k8sApplication from "../components/k8sApplication.vue";
import configAndStorage from "../components/configAndStorage.vue";
import configMap from "../components/configMap.vue";
import pvcList from "../components/pvcList.vue";
import pvList from "../components/pvList.vue";
import ingressAndService from "../components/ingressAndService.vue";
import ingressList from "../components/ingressList.vue";
import serviceList from "../components/serviceList.vue";
import ingressClass from "../components/ingressClass.vue";
import schedulingTemplatePage from "../components/schedulingTemplatePage.vue";
import kubeSchedulingUpdateMethod from "../components/kubeSchedulingUpdateMethod.vue";
import nodeAffinity from "../components/nodeAffinity.vue";
import probe from "../components/probe.vue";
import priorityList from "../components/priorityList.vue";
import dockerImage from "../components/dockerImage.vue";
import helmList from "../components/helmList.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/statistics",
    components: {
      first: Home,
    },
    children: [
      {
        path: "/monitor/slb",
        name: "slb",
        redirect: "/monitor/slb/ruleList",
        components: {
          second: slb,
        },
        children: [
          {
            path: "/monitor/slb/ruleList",
            name: "ruleList",
            components: {
              three: ruleList,
            },
          },
        ],
      },
      {
        path: "/flameGraphFile",
        name: "flameGraphFile",
        components: {
          second: flameGraphFile,
        },
      },
      {
        path: "/nodeList",
        name: "nodeList",
        components: {
          second: nodeList,
        },
      },
      {
        path: "/event",
        name: "event",
        redirect: "/event/eventPage",
        components: {
          second: event,
        },
        children: [
          {
            path: "/event/eventPage",
            name: "eventPage",
            components: {
              three: eventPage,
            },
          },
          {
            path: "/event/eventHistoryPage",
            name: "eventHistoryPage",
            components: {
              three: eventPage,
            },
          },
        ],
      },
      {
        path: "/podList",
        name: "podList",
        components: {
          second: podList,
        },
      },
      {
        path: "/deploymentTab",
        name: "deploymentTab",
        redirect: "/deploymentTab/deploymentList",
        components: {
          second: deploymentTab,
        },
        children: [
          {
            path: "/deploymentTab/deploymentList",
            name: "deploymentList",
            components: {
              three: deploymentList,
            },
          },
          {
            path: "/deploymentTab/customDeployment",
            name: "customDeployment",
            components: {
              three: k8sApplication,
            },
          },
        ],
      },
      {
        path: "/statefulSetTab",
        name: "statefulSetTab",
        redirect: "/statefulSetTab/statefulSetList",
        components: {
          second: statefulSetTab,
        },
        children: [
          {
            path: "/statefulSetTab/statefulSetList",
            name: "statefulSetList",
            components: {
              three: statefulSetList,
            },
          },
          {
            path: "/statefulSetTab/customStatefulSet",
            name: "customStatefulSet",
            components: {
              three: k8sApplication,
            },
          },
        ],
      },
      {
        path: "/configAndStorage",
        name: "configAndStorage",
        redirect: "/configAndStorage/configMap",
        components: {
          second: configAndStorage,
        },
        children: [
          {
            path: "/configAndStorage/configMap",
            name: "configMap",
            components: {
              three: configMap,
            },
          },
          {
            path: "/configAndStorage/pvcList",
            name: "pvcList",
            components: {
              three: pvcList,
            },
          },
          {
            path: "/configAndStorage/pvList",
            name: "pvList",
            components: {
              three: pvList,
            },
          },
        ],
      },
      {
        path: "/ingressAndService",
        name: "ingressAndService",
        redirect: "/ingressAndService/ingressList",
        components: {
          second: ingressAndService,
        },
        children: [
          {
            path: "/ingressAndService/ingressList",
            name: "ingressList",
            components: {
              three: ingressList,
            },
          },
          {
            path: "/ingressAndService/serviceList",
            name: "serviceList",
            components: {
              three: serviceList,
            },
          },
          {
            path: "/ingressAndService/ingressClass",
            name: "ingressClass",
            components: {
              three: ingressClass,
            },
          },
        ],
      },
      {
        path: "/schedulingTemplatePage",
        name: "schedulingTemplatePage",
        redirect: "/schedulingTemplatePage/kubeSchedulingUpdateMethod",
        components: {
          second: schedulingTemplatePage,
        },
        children: [
          {
            path: "/schedulingTemplatePage/kubeSchedulingUpdateMethod",
            name: "kubeSchedulingUpdateMethod",
            components: {
              three: kubeSchedulingUpdateMethod,
            },
          },
          {
            path: "/schedulingTemplatePage/nodeAffinity",
            name: "nodeAffinity",
            components: {
              three: nodeAffinity,
            },
          },
          {
            path: "/schedulingTemplatePage/probe",
            name: "probe",
            components: {
              three: probe,
            },
          },
          {
            path: "/schedulingTemplatePage/priorityList",
            name: "priorityList",
            components: {
              three: priorityList,
            },
          },
          {
            path: "/schedulingTemplatePage/dockerImage",
            name: "dockerImage",
            components: {
              three: dockerImage,
            },
          },
          {
            path: "/schedulingTemplatePage/helmList",
            name: "helmList",
            components: {
              three: helmList,
            },
          },
        ],
      },
      {
        path: "/projectManagement",
        name: "projectManagement",
        redirect: "/projectManagement/projectList",
        components: {
          second: projectManagement,
        },
        children: [
          {
            path: "/projectManagement/projectList",
            name: "projectList",
            components: {
              three: projectList,
            },
          },
          {
            path: "/projectManagement/timeControlModelList",
            name: "timeControlModelList",
            components: {
              three: timeControlModelList,
            },
          },
        ],
      },
      {
        path: "/workbench",
        name: "workbench",
        redirect: "/workbench/general",
        components: {
          second: workbenchType,
        },
        children: [
          {
            path: "/workbench/general",
            name: "general",
            components: {
              three: workbench,
            },
          },
          {
            path: "/workbench/schedule",
            name: "schedule",
            components: {
              three: workbench,
            },
          },
          {
            path: "/workbench/store",
            name: "store",
            components: {
              three: workbench,
            },
          },
        ],
      },
      {
        path: "/subprojectList",
        name: "subprojectList",
        components: {
          second: subprojectList,
        },
      },
      {
        path: "/serverList",
        name: "serverList",
        redirect: "/serverList/serverListTab",
        components: {
          second: serverList,
        },
        children: [
          {
            path: "/serverList/serverListTab",
            name: "serverListTab",
            components: {
              three: serverListTab,
            },
          },
          {
            path: "/serverList/serverAccountListTab",
            name: "serverAccountListTab",
            components: {
              three: serverAccountListTab,
            },
          },
          {
            path: "/serverList/serverGroup",
            name: "serverGroup",
            components: {
              three: serverGroup,
            },
          },
          {
            path: "/serverList/serverConfigTemplate",
            name: "serverConfigTemplate",
            components: {
              three: serverConfigTemplate,
            },
          },
          {
            path: "/serverList/serverPortInfoList",
            name: "serverPortInfoList",
            components: {
              three: serverPortInfoList,
            },
          },
          {
            path: "/serverList/shellLog",
            name: "shellLog",
            components: {
              three: shellLog,
            },
          },
        ],
      },
      {
        path: "/request",
        name: "request",
        redirect: "/request/postApplication",
        components: {
          second: request,
        },
        children: [
          {
            path: "/request/postApplication",
            name: "postApplication",
            components: {
              three: postApplication,
            },
          },
          {
            path: "/request/publishLog",
            name: "publishLog",
            components: {
              three: publishLog,
            },
          },
        ],
      },
      {
        path: "/statistics",
        name: "statistics",
        components: {
          second: statistics,
        },
      },
      {
        path: "/postingDetails",
        name: "postingDetails",
        components: {
          second: postingDetails,
        },
      },
      {
        path: "/otherDeploy",
        name: "otherDeploy",
        components: {
          second: otherDeploy,
        },
        children: [
          {
            path: "/otherDeploy/compiler",
            name: "compiler",
            components: {
              three: compiler,
            },
          },
          {
            path: "/otherDeploy/environment",
            name: "environment",
            components: {
              three: environment,
            },
          },
        ],
      },
      {
        path: "/errorLog",
        name: "errorLog",
        components: {
          second: errorLog,
        },
        redirect: "/errorLog/errorLogList",
        children: [
          {
            path: "/errorLog/errorLogList",
            name: "errorLogList",
            components: {
              three: errorLogList,
            },
          },
          {
            path: "/errorLog/errorLogStatistics",
            name: "errorLogStatistics",
            components: {
              three: errorLogStatistics,
            },
          },
        ],
      },
      {
        path: "/batchExecute",
        name: "batchExecute",
        components: {
          second: batchExecute,
        },
      },
      {
        path: "/shell",
        name: "shell",
        redirect: "/shell/shellTiming",
        components: {
          second: shell,
        },
        children: [
          {
            path: "/shell/shellTiming",
            name: "shellTiming",
            components: {
              three: shellTiming,
            },
          },
          {
            path: "/shell/shellExecute",
            name: "shellExecute",
            components: {
              three: shellExecute,
            },
          },
        ],
      },
      {
        path: "/domainAdmin/domainNameDNS",
        name: "domainNameDNS",
        components: {
          second: domainNameDNS,
        },
      },
      {
        path: "/domainAdmin",
        name: "domainAdmin",
        components: {
          second: domainAdmin,
        },
      },
      {
        path: "/aliyunAccountManagement",
        name: "aliyunAccountManagement",
        redirect: "/aliyunAccountManagement/mainAccountPage",
        components: {
          second: aliyunAccountManagement,
        },
        children: [
          {
            path: "/aliyunAccountManagement/mainAccountPage",
            name: "mainAccountPage",
            components: {
              three: mainAccountPage,
            },
          },
          {
            path: "/aliyunAccountManagement/policyPage",
            name: "policyPage",
            components: {
              three: policyPage,
            },
          },
        ],
      },
      {
        path: "/ramAccountPage",
        name: "ramAccountPage",
        components: {
          second: ramAccountPage,
        },
      },
      {
        path: "/vpnManager",
        name: "vpnManager",
        components: {
          second: vpnManager,
        },
      },
      {
        path: "/certificate",
        name: "certificate",
        redirect: "/certificate/certificateManagement",
        components: {
          second: certificate,
        },
        children: [
          {
            path: "/certificate/certificateManagement",
            name: "certificateManagement",
            components: {
              three: certificateManagement,
            },
          },
          {
            path: "/certificate/certificateDomain",
            name: "certificateDomain",
            components: {
              three: certificateDomain,
            },
          },
        ],
      },
      {
        path: "/diskManage",
        name: "diskManage",
        redirect: "/diskManage/serverDiskList",
        components: {
          second: diskManage,
        },
        children: [
          {
            path: "/diskManage/serverDiskList",
            name: "serverDiskList",
            components: {
              three: serverDiskList,
            },
          },
          {
            path: "/diskManage/diskList",
            name: "diskList",
            components: {
              three: diskList,
            },
          },
        ],
      },
      {
        path: "/securityGroupManagement",
        name: "securityGroupManagement",
        components: {
          second: securityGroupManagement,
        },
      },
      {
        path: "/securityGroupItem",
        name: "securityGroupItem",
        components: {
          second: securityGroupItem,
        },
      },
      {
        path: "/warning",
        name: "warning",
        redirect: "/warning/warningGroupPage",
        components: {
          second: warning,
        },
        children: [
          {
            path: "/warning/warningGroupPage",
            name: "warningGroupPage",
            components: {
              three: warningGroupPage,
            },
          },
          {
            path: "/warning/warningConfigPage",
            name: "warningConfigPage",
            components: {
              three: warningConfigPage,
            },
          },
          {
            path: "/warning/warningMemberPage",
            name: "warningMemberPage",
            components: {
              three: warningMemberPage,
            },
          },
          {
            path: "/warning/warningRecordPage",
            name: "warningRecordPage",
            components: {
              three: warningRecordPage,
            },
          },
        ],
      },
      {
        path: "/topic",
        name: "topic",
        components: {
          second: topic,
        },
      },
      {
        path: "/mqTopic",
        name: "mqTopic",
        components: {
          second: mqTopic,
        },
      },
      {
        path: "/mqTopicDetail",
        name: "mqTopicDetail",
        redirect: "/mqTopicDetail/mqMessage",
        components: {
          second: mqTopicDetail,
        },
        children: [
          {
            path: "/mqTopicDetail/mqMessage",
            name: "mqMessage",
            components: {
              three: mqMessage,
            },
          },
        ],
      },
      {
        path: "/monitor",
        name: "monitor",
        redirect: "/monitor/apiGateway",
        components: {
          second: monitor,
        },
        children: [
          {
            path: "/monitor/apiGateway",
            name: "apiGateway",
            components: {
              three: apiGateway,
            },
          },
          {
            path: "/monitor/nginxGateway",
            name: "nginxGateway",
            components: {
              three: nginxGateway,
            },
          },
        ],
      },
      {
        path: "/nginxInstanceList",
        name: "nginxInstanceList",
        redirect: "/nginxInstanceList/nginxInstance",
        components: {
          second: nginxInstanceList,
        },
        children: [
          {
            path: "/nginxInstanceList/nginxInstance",
            name: "nginxInstance",
            components: {
              three: nginxInstance,
            },
          },
          {
            path: "/nginxInstanceList/globalConfiguration",
            name: "globalConfiguration",
            components: {
              three: globalConfiguration,
            },
          },
        ],
      },
      {
        path: "/serverGroupList",
        name: "serverGroupList",
        redirect: "/serverGroupList/listenServerGroupList",
        components: {
          second: serverGroupList,
        },
        children: [
          {
            path: "/serverGroupList/listenServerGroupList",
            name: "listenServerGroupList",
            components: {
              three: listenServerGroupList,
            },
          },
          {
            path: "/serverGroupList/nginxServerGroup",
            name: "nginxServerGroup",
            components: {
              three: nginxServerGroup,
            },
          },
        ],
      },
      {
        path: "/serverGroupList/listenServerGroupList/listenServerGroupItemList",
        name: "listenServerGroupItemList",
        components: {
          second: listenServerGroupItemList,
        },
      },
      {
        path: "/serverGroupList/nginxServerGroup/nginxServer",
        name: "nginxServer",
        components: {
          second: nginxServer,
        },
      },
      {
        path: "/mqConsumer",
        name: "mqConsumer",
        components: {
          second: mqConsumer,
        },
      },
      {
        path: "/mqProducer",
        name: "mqProducer",
        components: {
          second: mqProducer,
        },
      },
      {
        path: "/mqCluster",
        name: "mqCluster",
        components: {
          second: mqCluster,
        },
      },
      {
        path: "/publickeyManagement",
        name: "publickeyManagement",
        redirect: "/publickeyManagement/publickeyList",
        components: {
          second: publickeyManagement,
        },
        children: [
          {
            path: "/publickeyManagement/publickeyList",
            name: "publickeyList",
            components: {
              three: publickeyList,
            },
          },
          {
            path: "/publickeyManagement/trustList",
            name: "trustList",
            components: {
              three: trustList,
            },
          },
        ],
      },
      {
        path: "/ansible",
        name: "ansible",
        redirect: "/ansible/ansiblePlaybook",
        components: {
          second: ansible,
        },
        children: [
          {
            path: "/ansible/ansiblePlaybook",
            name: "ansiblePlaybook",
            components: {
              three: ansiblePlaybook,
            },
          },
          {
            path: "/ansible/ansibleTask",
            name: "ansibleTask",
            components: {
              three: ansibleTask,
            },
          },
        ],
      },
      {
        path: "/generatorCode",
        name: "generatorCode",
        redirect: "/generatorCode/templateCode",
        components: {
          second: generatorCode,
        },
        children: [
          {
            path: "/generatorCode/templateCode",
            name: "templateCode",
            components: {
              three: templateCode,
            },
          },
          {
            path: "/generatorCode/templated",
            name: "templated",
            components: {
              three: templated,
            },
          },
          {
            path: "/generatorCode/templateParameter",
            name: "templateParameter",
            components: {
              three: templateParameter,
            },
          },
          {
            path: "/generatorCode/templateProject",
            name: "templateProject",
            components: {
              three: templateProject,
            },
          },
        ],
      },
      {
        path: "/interfaceManagement",
        name: "interfaceManagement",
        redirect: "/interfaceManagement/innerApi",
        components: {
          second: interfaceManagement,
        },
        children: [
          {
            path: "/interfaceManagement/innerApi",
            name: "innerApi",
            components: {
              three: innerApi,
            },
          },
          {
            path: "/interfaceManagement/outerApi",
            name: "outerApi",
            components: {
              three: outerApi,
            },
          },
        ],
      },
      {
        path: "/zookeeperUIList",
        name: "zookeeperUIList",
        components: {
          second: zookeeperUIList,
        },
      },
      {
        path: "/mvnManagement",
        name: "mvnManagement",
        redirect: "/mvnManagement/moduleList",
        components: {
          second: mvnManagement,
        },
        children: [
          {
            path: "/mvnManagement/moduleList",
            name: "moduleList",
            components: {
              three: moduleList,
            },
          },
          {
            path: "/mvnManagement/manualUpload",
            name: "manualUpload",
            components: {
              three: manualUpload,
            },
          },
          {
            path: "/mvnManagement/projectPom",
            name: "projectPom",
            components: {
              three: projectPom,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/yapi",
    name: "yapi",
    redirect: "/allApiList",
    components: {
      first: yapi,
    },
    children: [
      {
        path: "/allApiList",
        name: "allApiList",
        components: {
          second: allApiList,
        },
      },
      {
        path: "/apiDetail",
        name: "apiDetail",
        components: {
          second: apiDetail,
        },
      },
    ],
  },
  {
    path: "/pipeline",
    name: "pipeline",
    components: {
      first: pipeline,
    },
  },
  {
    path: "/zookeeperUI",
    name: "zookeeperUI",
    components: {
      first: zookeeperUI,
    },
  },
  {
    path: "/sshShell",
    name: "sshShell",
    components: {
      first: sshShell,
    },
  },
  {
    path: "/terminal",
    name: "terminal",
    components: {
      first: terminal,
    },
  },
  {
    path: "/interfaceView",
    name: "interfaceView",
    components: {
      first: interfaceView,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default router;
