<template>
  <div class="warningMemberPage">
    <div class="searchCondition">
      <div class="searchConditionItem">
        告警组：
        <a-select
          v-model="groupId"
          placeholder="请选择告警组"
          showSearch
          allowClear
        >
          <a-select-option v-for="item in categoryList" :key="item.id">{{
            item.value
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        用户：
        <a-select
          v-model="userLoginName"
          placeholder="请选择用户"
          style="width: 200px"
          allowClear
          showSearch
          :filter-option="false"
          @search="getUserList"
        >
          <a-select-option v-for="item in userList" :key="item.loginName">
            {{ item.userName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.userName }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="组名:" required>
          <a-select
            v-model="groupIdAdd"
            placeholder="请选择告警组"
            showSearch
            allowClear
          >
            <a-select-option v-for="item in categoryList" :key="item.id">{{
              item.value
            }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="用户登录名:" required>
          <a-select
            v-model="userLoginNameAdd"
            style="width: 100%"
            :filterOption="false"
            @search="getUserList"
            placeholder="请输入关键词"
            allowClear
            showSearch
          >
            <a-select-option v-for="item in userList" :key="item.loginName">{{
              item.userName
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/warningMemberPage.js";
import { userList } from "../lib/projectList.js";

export default {
  name: "warningMemberPage",
  data() {
    return {
      userLoginName: undefined,
      groupName: "",
      groupId: undefined,
      groupIdAdd: undefined,
      categoryList: [],
      userLoginNameAdd: undefined,
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "用户登录名",
          ellipsis: true,
          dataIndex: "userName",
        },
        {
          title: "分组",
          ellipsis: true,
          dataIndex: "groupIdLabel",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      userList: [],
    };
  },
  mounted() {
    this.getUserList();
    this.getWarningList();
  },
  methods: {
    getUserList(name) {
      let data = { name };
      userList(data).then((res) => {
        if (res.result == 200) {
          this.userList = res.data.userList;
        }
      });
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        groupId: this.groupId,
        userLoginName: this.userLoginName,
      };
      this.getmemberPageList(data);
    },
    // 告警组成员分页列表
    getmemberPageList(data) {
      this.loadingTable = true;
      api
        .getmemberPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.groupIdLabel = "";
              this.categoryList.forEach((menu) => {
                if (menu.id === item.groupId) {
                  item.groupIdLabel = menu.value;
                }
              });
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 新增
    addClick() {
      this.addKeyVisible = true;
      this.groupIdAdd = undefined;
      this.userLoginNameAdd = undefined;
    },
    // 确定新增
    add_submit() {
      let data = {
        groupId: this.groupIdAdd,
        userLoginName: this.userLoginNameAdd,
      };
      if (!data.groupId) {
        this.$message.warning("请选择告警组");
        return;
      }
      if (!data.userLoginName) {
        this.$message.warning("请输入用户登录名");
        return;
      }
      api.addMemberToGroup(data).then((res) => {
        if (res.result === 200) {
          this.addKeyVisible = false;
          this.$message.success("添加成功");
          this.query();
        }
      });
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.userLoginNameAdd = undefined;
      this.groupId = undefined;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteMemberFromGroup(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 告警组转换用
    getWarningList() {
      api.groupList().then((res) => {
        if (res.result === 200) {
          let list = [];
          for (let i in res.data) {
            let obj = {
              id: res.data[i].id,
              value: res.data[i].groupName,
            };
            list.push(obj);
          }
          this.categoryList = list;
          this.query(1);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
