<template>
  <div class="serviceList">
    <div class="reminder">服务 <span>service</span></div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.name"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="label" slot-scope="text, record">
        <template v-if="record.label && Object.keys(record.label).length > 0">
          <template v-for="(value, key) in record.label">
            <a-tag style="margin-top: 5px"> {{ key }}:{{ value }} </a-tag>
          </template>
        </template>
        <template v-else> - </template>
      </span>
      <span slot="innerEndPoint" slot-scope="text, record">
        <template
          v-if="
            record.innerEndPoint && Object.keys(record.innerEndPoint).length > 0
          "
        >
          <template v-for="item in record.innerEndPoint">
            <div>{{ item }}</div>
          </template>
        </template>
        <template v-else> - </template>
      </span>
      <span slot="outerEndPoint" slot-scope="text, record">
        <template
          v-if="
            record.outerEndPoint && Object.keys(record.outerEndPoint).length > 0
          "
        >
          <template v-for="item in record.outerEndPoint">
            <div>{{ item }}</div>
          </template>
        </template>
        <template v-else> - </template>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <a-popconfirm
      placement="right"
      okText="确认"
      cancelText="取消"
      @confirm="batchDeleteClick()"
      :disabled="selectedRowKeys.length == 0"
    >
      <template slot="title">确认是否批量删除</template>
      <a-button
        type="danger"
        icon="delete"
        :disabled="selectedRowKeys.length == 0"
        >批量删除</a-button
      >
    </a-popconfirm>

    <a-drawer
      title="新增"
      placement="right"
      :visible="updateShow"
      @close="updateShow = false"
      width="1000"
    >
      <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
        <a-form-item label="命名空间:">
          <a-select
            v-model="updateData.namespace"
            placeholder="请选择命名空间"
            style="width: 200px"
          >
            <a-select-option
              v-for="(item, index) in namespaceList"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="名称:">
          <a-input
            v-model="updateData.name"
            placeholder="请输入名称"
            style="width: 200px"
          />
        </a-form-item>
        <a-form-item label="类型:">
          <a-select v-model="updateData.type" style="width: 200px">
            <a-select-option
              v-for="(item, index) in typeList"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="ports:">
          <a-button type="link" @click="addPort()" size="small" icon="plus"
            >添加port</a-button
          >
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(portsTableColumns)"
            :columns="portsTableColumns"
            :dataSource="updateData.ports"
            :pagination="false"
            size="small"
          >
            <span slot="protocol" slot-scope="text, record">
              <a-select v-model="record.protocol" style="width: 200px">
                <a-select-option
                  v-for="(item, index) in protocolList"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </span>
            <span slot="port" slot-scope="text, record">
              <a-input v-model="record.port" />
            </span>
            <span slot="targetPort" slot-scope="text, record">
              <a-input v-model="record.targetPort" />
            </span>
            <span slot="action" slot-scope="text, record, index">
              <a
                href="javascript:;"
                style="color: #ff4d4f"
                @click="deletePort(index)"
                :disabled="updateData.ports.length == 1"
                >删除</a
              >
            </span>
          </a-table>
        </a-form-item>
      </a-form>

      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="updateShow = false">
          取消
        </a-button>
        <a-button type="primary" @click="updateSubmit"> 确定 </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import * as api from "../lib/k8s.js";

export default {
  name: "serviceList",
  props: ["namespace", "namespaceList"],
  data() {
    return {
      name: "",
      tableColumns: [
        {
          title: "名称",
          dataIndex: "name",
          width: 200,
        },
        {
          title: "命名空间",
          dataIndex: "namespace",
          width: 100,
        },
        {
          title: "标签",
          dataIndex: "label",
          scopedSlots: { customRender: "label" },
          width: 200,
        },
        {
          title: "类型",
          dataIndex: "type",
          width: 80,
        },
        {
          title: "集群 IP",
          dataIndex: "ip",
          width: 150,
        },
        {
          title: "内部端点",
          dataIndex: "innerEndPoint",
          scopedSlots: { customRender: "innerEndPoint" },
          width: 300,
        },
        {
          title: "外部端点",
          dataIndex: "outerEndPoint",
          scopedSlots: { customRender: "outerEndPoint" },
          width: 200,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 80,
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      selectedRowKeys: [],
      updateShow: false,
      updateData: {},
      typeList: ["NodePort"],
      protocolList: ["TCP", "UDP"],
      portsTableColumns: [
        {
          title: "端口",
          dataIndex: "port",
          scopedSlots: { customRender: "port" },
          width: 100,
        },
        {
          title: "目标端口",
          dataIndex: "targetPort",
          scopedSlots: { customRender: "targetPort" },
          width: 100,
        },
        {
          title: "协议",
          dataIndex: "protocol",
          scopedSlots: { customRender: "protocol" },
          width: 200,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 60,
        },
      ],
    };
  },
  watch: {
    namespace: {
      immediate: true,
      handler() {
        this.query();
      },
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    query() {
      let data = {
        namespace: this.namespace,
        name: this.name,
      };
      this.loadingTable = true;
      api
        .serviceList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    add() {
      this.updateName = "";
      this.updateData = {
        name: "",
        namespace: "default",
        type: "NodePort",
        ports: [
          {
            port: 80,
            targetPort: 8080,
            protocol: "TCP",
          },
        ],
      };
      this.updateShow = true;
    },
    addPort() {
      this.updateData.ports.push({
        port: 80,
        targetPort: 8080,
        protocol: "TCP",
      });
    },
    deletePort(index) {
      this.updateData.ports.splice(index, 1);
    },
    updateSubmit() {
      let data = { ...this.updateData };
      api.createService(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("新增成功");
          this.updateShow = false;
          this.query();
        }
      });
    },
    deleteClick(val) {
      let data = {
        name: val.name,
        namespace: val.namespace,
      };
      api.deleteService(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    batchDeleteClick() {
      let arr = [];
      this.selectedRowKeys.forEach((name) => {
        this.tableDataSource.forEach((item) => {
          if (item.name == name) {
            arr.push(item);
          }
        });
      });
      this.$axios
        .all(
          arr.map((item) => {
            let data = {
              name: item.name,
              namespace: item.namespace,
            };
            return api.deleteService(data);
          })
        )
        .then((resArr) => {
          this.$message.success("删除成功");
          this.query();
        });
    },
  },
};
</script>
