<template>
  <div class="serverDiskList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        服务器：
        <a-select
          v-model="serverId"
          placeholder="请选择服务器"
          showSearch
          :filter-option="false"
          @search="instanceIdSearch"
          allowClear
        >
          <a-select-option v-for="item in instanceList" :key="item.id">{{
            item.instanceName
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        磁盘名：
        <a-input
          placeholder="请输入磁盘名"
          v-model.trim="diskName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        百分比：
        <a-input-number
          v-model.trim="percentStart"
          :min="0"
          :max="100"
          @pressEnter="query(1)"
        />
        -
        <a-input-number
          v-model.trim="percentEnd"
          :min="0"
          :max="100"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="size" slot-scope="size"> {{ size + "G" }} </span>
      <span slot="percent" slot-scope="percent, record">
        <a-tooltip>
          <template slot="title">
            {{ record.usedSize + "G" }}/ {{ record.size + "G" }}</template
          >
          <a-progress
            :percent="percent"
            size="small"
            :strokeColor="percent >= record.alarmThreshold ? 'red' : ''"
          />
        </a-tooltip>
      </span>
      <span slot="alarmThreshold" slot-scope="alarmThreshold, record">
        {{ alarmThreshold }}
        <a-icon
          type="edit"
          @click="editAlarmThreshold(record)"
          style="color: #1890ff"
        />
      </span>
    </a-table>

    <a-modal
      title="修改告警阈值"
      v-model="editAlarmThresholdShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="告警阈值:">
          <a-input-number
            v-model.trim="editAlarmThresholdData.alarmThreshold"
            :min="0"
            :max="100"
            placeholder="请输入告警阈值"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editAlarmThresholdShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="editAlarmThresholdSubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/serverDiskList.js";
export default {
  name: "serverDiskList",
  data() {
    return {
      serverId: undefined,
      instanceList: [],
      diskName: "",
      percentStart: "0",
      percentEnd: "100",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 80,
        },
        {
          title: "服务器",
          ellipsis: true,
          dataIndex: "serverName",
          width: 250,
        },
        {
          title: "磁盘名称",
          ellipsis: true,
          dataIndex: "diskName",
          width: 200,
        },
        {
          title: "挂载目录",
          ellipsis: true,
          dataIndex: "mountPoint",
          width: 150,
        },
        {
          title: "容量",
          ellipsis: true,
          dataIndex: "size",
          scopedSlots: { customRender: "size" },
          width: 80,
        },
        {
          title: "使用百分比",
          ellipsis: true,
          dataIndex: "percent",
          scopedSlots: { customRender: "percent" },
          width: 250,
        },
        {
          title: "告警阈值",
          ellipsis: true,
          dataIndex: "alarmThreshold",
          scopedSlots: { customRender: "alarmThreshold" },
          width: 100,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      editAlarmThresholdData: {},
      editAlarmThresholdShow: false,
    };
  },
  mounted() {
    this.getServerList();
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        serverId: this.serverId,
        diskName: this.diskName,
        percentStart: this.percentStart,
        percentEnd: this.percentEnd,
      };
      this.getDiskList(data);
    },
    // 查询告警配置分页列表
    getDiskList(data) {
      this.loadingTable = true;
      api
        .getDisk(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    editAlarmThreshold(record) {
      this.editAlarmThresholdData = {
        id: record.id,
        alarmThreshold: record.alarmThreshold,
      };
      this.editAlarmThresholdShow = true;
    },
    editAlarmThresholdSubmit() {
      let data = { ...this.editAlarmThresholdData };
      api.editAlarmThreshold(data).then((res) => {
        if (res.result === 200) {
          this.editAlarmThresholdShow = false;
          this.$message.success("修改成功");
          this.query();
        }
      });
    },
    // 服务器下拉框发生变化
    instanceIdSearch(instanceName) {
      this.getServerList(instanceName);
    },
    // 查询服务器列表
    getServerList(instanceName) {
      let data = {
        instanceName: instanceName,
      };
      api.serverList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.backup {
  padding: 5px 0;
}
</style>
