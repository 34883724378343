<template>
  <div class="diskList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        磁盘名：
        <a-input
          placeholder="请输入磁盘名"
          v-model.trim="diskName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        百分比：
        <a-input-number
          v-model.trim="percentStart"
          :min="0"
          :max="100"
          @pressEnter="query(1)"
        />
        -
        <a-input-number
          v-model.trim="percentEnd"
          :min="0"
          :max="100"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="type" slot-scope="type, record">
        {{ type == "data" ? "数据盘" : "系统盘" }}
      </span>
      <span slot="category" slot-scope="category, record">
        <span v-for="(item, index) in categoryList" :key="index">
          <span v-if="category === item.value">{{ item.label }}</span>
        </span>
      </span>
      <span slot="status" slot-scope="text, record">
        <a-tag :color="record.statusColor">{{ record.statusLabel }}</a-tag>
      </span>
      <span
        slot="average"
        slot-scope="average, record"
        style="float: left; width: 90%"
      >
        <a-progress
          :percent="average"
          size="small"
          :strokeColor="average >= record.alarmThreshold ? 'red' : ''"
        />
      </span>
      <span slot="alarmThreshold" slot-scope="alarmThreshold, record">
        {{ alarmThreshold }}
        <a-icon
          type="edit"
          @click="editAliyunDiskInfo(record)"
          style="color: #1890ff"
        />
      </span>
    </a-table>

    <a-modal
      title="修改告警阈值"
      v-model="editAliyunDiskInfoShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="告警阈值:">
          <a-input-number
            v-model.trim="editAliyunDiskInfoData.alarmThreshold"
            :min="0"
            :max="100"
            placeholder="请输入告警阈值"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editAliyunDiskInfoShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="editAliyunDiskInfoSubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/diskList.js";
export default {
  name: "diskList",
  data() {
    return {
      diskName: "",
      percentStart: "0",
      percentEnd: "100",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "实例名称",
          ellipsis: true,
          dataIndex: "instanceName",
          width: 200,
        },
        {
          title: "ip",
          ellipsis: true,
          dataIndex: "privateIp",
          width: 120,
          align: "center",
        },
        {
          title: "磁盘序列号",
          ellipsis: true,
          dataIndex: "diskId",
          width: 180,
        },
        {
          title: "磁盘类型",
          ellipsis: true,
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
          width: 70,
          align: "center",
        },
        {
          title: "磁盘种类",
          ellipsis: true,
          dataIndex: "category",
          scopedSlots: { customRender: "category" },
          width: 140,
          align: "center",
        },
        {
          title: "磁盘名称",
          ellipsis: true,
          dataIndex: "diskName",
          width: 200,
        },
        {
          title: "性能等级",
          ellipsis: true,
          dataIndex: "performanceLevel",
          width: 70,
          align: "center",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: 90,
          align: "center",
        },
        {
          title: "使用百分比",
          ellipsis: true,
          dataIndex: "average",
          scopedSlots: { customRender: "average" },
          width: 200,
          align: "center",
        },
        {
          title: "告警阈值",
          ellipsis: true,
          dataIndex: "alarmThreshold",
          scopedSlots: { customRender: "alarmThreshold" },
          width: 70,
        },
        {
          title: "挂载时间",
          ellipsis: true,
          dataIndex: "attachedTime",
          width: 150,
        },
        {
          title: "卸载时间",
          ellipsis: true,
          dataIndex: "detachedTime",
          width: 150,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "creationTime",
          width: 150,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      editAliyunDiskInfoData: {},
      editAliyunDiskInfoShow: false,
      categoryList: [
        { label: "普通云盘", value: "cloud" },
        { label: "高效云盘", value: "cloud_efficiency" },
        { label: "SSD 盘", value: "cloud_ssd" },
        { label: "ESSD 云盘", value: "cloud_essd" },
        { label: "ESSD AutoPL 云盘", value: "cloud_auto" },
        { label: "I/O 密集型本地盘", value: "local_ssd_pro" },
        { label: "吞吐密集型本地盘", value: "local_hdd_pro" },
        { label: "ESSD Entry 云盘", value: "cloud_essd_entry" },
      ],
      statusList: [
        { label: "使用中", value: "In_use", color: "green" },
        { label: "待挂载", value: "Available", color: "orange" },
        { label: "挂载中", value: "Attaching", color: "orange" },
        { label: "卸载中", value: "Detaching", color: "orange" },
        { label: "创建中", value: "Creating", color: "green" },
        { label: "初始化中", value: "ReIniting", color: "green" },
      ],
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        diskName: this.diskName,
        percentStart: this.percentStart,
        percentEnd: this.percentEnd,
      };
      this.getDiskList(data);
    },
    // 查询告警配置分页列表
    getDiskList(data) {
      this.loadingTable = true;
      api
        .aliyunDiskInfoPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data.records;
            this.tableDataSource.forEach((item) => {
              let statusObj = this.statusList.filter((statusItem) => {
                return statusItem.value == item.status;
              })[0];

              this.$set(item, "statusColor", statusObj.color);
              this.$set(item, "statusLabel", statusObj.label);
            });
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    editAliyunDiskInfo(record) {
      this.editAliyunDiskInfoData = {
        id: record.id,
        alarmThreshold: record.alarmThreshold,
      };
      this.editAliyunDiskInfoShow = true;
    },
    editAliyunDiskInfoSubmit() {
      let data = { ...this.editAliyunDiskInfoData };
      api.editAliyunDiskInfo(data).then((res) => {
        if (res.result === 200) {
          this.editAliyunDiskInfoShow = false;
          this.$message.success("修改成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.backup {
  padding: 5px 0;
}
</style>
