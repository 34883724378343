<template>
  <div class="diskManage">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="数据库磁盘列表">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="磁盘列表">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "diskManage",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/diskManage/serverDiskList") {
          this.active = "1";
        } else if (newVal == "/diskManage/diskList") {
          this.active = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/diskManage/serverDiskList") return;
          this.$router.replace("/diskManage/serverDiskList");
        }
        if (newVal === "2") {
          if (this.$route.path == "/diskManage/diskList") return;
          this.$router.replace("/diskManage/diskList");
        }
      },
    },
  },
};
</script>
