import http from "./axios";

// 查询告警组分页列表
export const aliyunDiskInfoPage = (data) => {
  return http.get("/aliyunDiskInfo/aliyunDiskInfoPage", data);
};

// 修改告警阈值
export const editAliyunDiskInfo = (data) => {
  return http.json_post("/aliyunDiskInfo/editAliyunDiskInfo", data);
};
